<template>
  <div class="contenedor-cpanel">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <BaseNotification2
        v-if="store.status.msg != null"
        :type="store.status.type"
        :text="store.status.msg"
        :closable="store.status.closable"
        @close="borrarNotificacion()"
      />
      <div class="contenedor-titulo">
        <h1>CPanel</h1>
        <div class="barra"></div>
      </div>
      <div class="contenedor_botones_cpanel">
        <router-link to="/socios" class="botones_cpanel">Listado de Socios</router-link>
        <router-link to="/crear-socio" class="botones_cpanel">Crear Socio</router-link>
        <router-link to="/listar-pagos" class="botones_cpanel">Listar Pagos</router-link>
        <router-link to="/crear-pago" class="botones_cpanel">Crear Pago</router-link>
        <router-link to="/convencion2025-estadistica" class="botones_cpanel">Convención 2025 <br> Merchandising</router-link>
        <!-- <router-link to="/estadistica-ventas" class="botones_cpanel">Estadística Carrito</router-link> -->
        <!-- <router-link to="/check-mercado-pago" class="botones_cpanel">Mercado Pago <br> Check Pagos</router-link> -->
        <!-- <router-link to="/listar-ventas-no-entregadas" class="botones_cpanel">Ventas NO Entregadas</router-link> -->
        <!-- <router-link to="/listar-ventas-entregadas" class="botones_cpanel">Ventas Entregadas</router-link> -->
        <!-- <router-link to="/puntos-de-entrega" class="botones_cpanel">Puntos de Entrega</router-link> -->
        <!-- <router-link to="/listar-servicios" class="botones_cpanel">Listar Servicios</router-link> -->
        <!-- <router-link to="/crear-servicio" class="botones_cpanel">Crear Servicio</router-link> -->
      </div>
    </section>
  </div>
</template>

<script>
import store from '../store'
import BaseNotification2 from '../components/BaseNotification2'
export default {
  name: 'PanelDeControl',
  components: {
    BaseNotification2
  },
  data: function () {
    return {
      store
    }
  },
  methods: {
    borrarNotificacion () {
      this.store.setStatus({
        msg: null,
        type: null,
        title: null
      })
    }
  },
  mounted: function () {
    setTimeout(() => {
      this.borrarNotificacion()
    }, 4000)
  }
}
</script>

<style>

</style>
