<template>
  <div class="contenedor-difusion">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Material Didáctico</h1>
        <div class="barra"></div>
      </div>
      <div class="contenedor_boton_carrito">
        <button class="icon icon-shopping-cart boton_shopping_cart" @click="abrirCarrito()">
          <span v-if="this.cantidadTotalCarrito >= 1">{{ this.cantidadTotalCarrito }}</span>
        </button>
      </div>
      <!-- <section class="contenedor-login">
        <button @click="AgregarCarrito(1)">Producto 1</button>
        <button @click="AgregarCarrito(2)">Producto 2</button>
        <button @click="AgregarCarrito(3)">Producto 3</button>
        <button @click="VaciarCarrito()">Vaciar Carrito</button>
      </section> -->
      <ul class="contenedor_productos">
        <li v-for="item in productos" :key="item.producto_id" class="producto_item">
          <img :src="imagenRuta(item.imagen_general)" :alt="item.producto_nombre">
          <div class="contenedor_info_producto">
            <h2>{{ item.producto_nombre }}</h2>
            <div>
              <span>$ {{ dotsCadaTres(parseFloat(item.precio_socio).toFixed(2).replace(/\.00$/, '')) }} (Socixs)</span>
              <span>$ {{ dotsCadaTres(parseFloat(item.precio_no_socio).toFixed(2).replace(/\.00$/, '')) }} (No Socixs)</span>
              <div v-if="item.stock_total != 0"><button @click="BotonAgregar(item.producto_id, item.producto_nombre, item.precio_socio, item.precio_no_socio, item.stock_total, item.imagen_general, item.precio_de_costo, variacionesProducto.talle_id, variacionesProducto.talle_nombre, variacionesProducto.color_id, variacionesProducto.color_nombre)" class="boton_producto_item" v-if="item.talles.length == 0">Agregar al Carrito</button><button v-else class="boton_producto_talle" @click="abrirModal(item.producto_id)">Elegir Talle</button></div>
              <div v-else><button class="boton_producto_item_sin_stock">Sin Stock</button></div>
              <div :class="`confirmation-${item.producto_id}`"
                class="confirmation"
              >
                <div class="contenor_modal_producto_talle_color" ref="modalRefTalleColor">
                  <img :src="imagenRuta(item.imagen_general)" :alt="item.producto_nombre">
                  <h2>{{ item.producto_nombre }}</h2>
                  <div class="contenedor_talle_modal">
                    <h3>Elegir Talle</h3>
                    <ul class="contenedor_talle_modal_lista">
                      <li v-for="subItem in item.talles" :key="`${subItem.talle_id}_${subItem.color_id}`">
                        <button @click="seleccionarTalle(item.producto_id, subItem.talle_id, subItem.talle_nombre)" class="boton_talle" :class="`boton_talle_${item.producto_id}_${subItem.talle_nombre}`">{{ subItem.talle_nombre }}</button>
                      </li>
                    </ul>
                  </div>
                  <div class="contenedor_color_modal">
                    <h3 v-if="mostrarElegirColor">Elegir Color</h3>
                    <div v-for="subItem in item.talles" :key="`${subItem.talle_id}_${subItem.color_id}`">
                        <ul :class="`confirmation_talle_${item.producto_id}_${subItem.talle_nombre}`" class="confirmation_talle">
                          <li v-for="subSubItem in subItem.colores"  :key="subSubItem.color_id">
                            <button @click="seleccionarColor(item.producto_id, subSubItem.color_id, subSubItem.color_nombre)" class="boton_color" :class="`boton_color_${item.producto_id}_${subSubItem.color_id}_${subSubItem.color_nombre}`">{{ subSubItem.color_nombre }}</button>
                          </li>
                        </ul>
                      </div>
                  </div>
                  <button @click="BotonAgregar(item.producto_id, item.producto_nombre, item.precio_socio, item.precio_no_socio, item.stock_total, item.imagen_general, item.precio_de_costo, variacionesProducto.talle_id, variacionesProducto.talle_nombre, variacionesProducto.color_id, variacionesProducto.color_nombre)" v-if="variacionesProducto.color_id !== null" class="boton_producto_item">Agregar al Carrito</button>
                  <button @click="cerrarModal(item.producto_id)" class="cerrar_modal_talle">X</button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div v-if="mostrarContenedorCarrito" class="contenedor_carrito">
        <div class="listado_carrito" ref="modalRef">
          <h2>Mi Carrito</h2>
          <button @click="cerrarCarrito()" class="cerrar_carrito">X</button>
          <div
            v-if="this.carrito.length !=0"
            class="contenedor_carrito_interno"
          >
            <ul>
            <li v-for="item in carrito" :key="`${item.producto_id}_${item.talle_id}_${item.color_id}`" class="listado_carrito_contenedor">
              <div class="listado_carrito_contenedor_img">
                <img :src="imagenRuta(item.imagen)" :alt="item.nombre">
              </div>
              <div class="listado_carrito_contenedor_info">
                <h3 class="listado_carrito_contenedor_info_nombre">{{ item.nombre }}</h3>
                <p class="listado_carrito_talle_info" v-if="item.talle_nombre !== null">Tallle: {{ item.talle_nombre }}</p>
                <p class="listado_carrito_talle_info" v-if="item.talle_nombre !== null">Color: {{ item.color_nombre }}</p>
                <div class="listado_carrito_contenedor_info_boton">
                  <button @click="Decrementar(item.producto_id, $event)" class="boton_incrementar_carrito boton_incrementar_carrito_decrementar"><span>&#8722;</span></button>
                  {{ item.cantidad }}
                  <button @click="Incrementar(item.producto_id, $event)" class="boton_incrementar_carrito boton_incrementar_carrito_incrementar"><span>&#43;</span></button>
                </div>
                <div class="listado_carrito_contenedor_info_precios">
                  <span>$ {{ dotsCadaTres(parseFloat(item.precio_total_socio).toFixed(2).replace(/\.00$/, '')) }} (Socixs)</span>
                  <span>$ {{ dotsCadaTres(parseFloat(item.precio_total_no_socio).toFixed(2).replace(/\.00$/, '')) }} (No Socixs)</span>
                </div>
                <div class="listado_carrito_contenedor_boton_borrar">
                  <button  @click="EliminarProducto(item.producto_id)" class="icon icon-trash-o boton_borrar_pagos_lista boton_pagos_lista"></button>
                </div>
              </div>
            </li>
          </ul>
          <button @click="VaciarCarrito()" class="boton_borrar_pagos_lista boton_vaciar_carrito">Vaciar Carrito</button>
          <div class="listado_carrito_contenedor_precios">
            <div class="listado_carrito_contenedor_precios_total">
              <p>Total</p>
            </div>
            <div class="listado_carrito_contenedor_precios_valor">
              <span v-if="this.precioCarritoSocio !== null">$ {{ dotsCadaTres(parseFloat(this.precioCarritoSocio).toFixed(2).replace(/\.00$/, '')) }} (Socixs)</span>
              <span v-if="this.precioCarritoSocio !== null">$ {{ dotsCadaTres(parseFloat(this.precioCarritoNoSocio).toFixed(2).replace(/\.00$/, '')) }} (No Socixs)</span>
            </div>
            <!-- <p v-if="this.precioCarritoSocio !== null">Precio Total SOCIO {{ this.precioCarritoSocio }}</p>
          <p v-if="this.precioCarritoSocio !== null">Precio Total NO SOCIO {{ this.precioCarritoNoSocio }}</p> -->
          </div>
          <form
            action="#"
            method="post"
            @submit.prevent="validarSocio(socio)"
            class="form_soy_socio"
          >
            <div class="form_section_soy_socio">
              <label for="dni">Ingresá tu DNI</label>
              <input
                type="text"
                name="dni"
                id="dni"
                v-model="socio.dni"
                :disabled="cargando"
              >
              <div
                class="form_soy_socio_mensaje_error"
                v-if="errores.dni !== null"
              >
                {{ errores.dni[0] }}
              </div>
            </div>
            <button type="submit" class="boton_guardar">Continuar</button>
          </form>
          <div
            v-if="pagoSocioAlDia"
            class="contenedor_pago_con_descuento"
          >
            <p>¡Tenés tu cuota al día!</p>
            <button @click="pagarCarrito('si')">Pagar Con Descuento<br>($ {{ dotsCadaTres(parseFloat(this.precioCarritoSocio).toFixed(2).replace(/\.00$/, '')) }})</button>
          </div>
          <div
            v-if="pagoSocioDeudor"
            class="contenedor_pago_con_descuento"
          >
            <p>¡No tenés tu cuota al día! <br> Tu compra es sin descuento</p>
            <button @click="pagarCarrito('no')">Pagar Sin Descuento<br>($ {{ dotsCadaTres(parseFloat(this.precioCarritoNoSocio).toFixed(2).replace(/\.00$/, '')) }})</button>
          </div>
          <div v-if="mostrarFormNoSocio" class="contenedor_form_carrito">
              <p>No sos Socix, completa tus datos</p>
               <form
                action="#"
                method="post"
                @submit.prevent="pagoCarritoNoSocio(practicante)"
                class="form_carrito"
              >
                <div class="form_section_seminario_no_socio">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    name="nombre"
                    id="nombre"
                    v-model="practicante.nombre"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.nombre !== null"
                  >
                    {{ erroresPracticante.nombre[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="apellido">Apellido</label>
                  <input
                    type="text"
                    name="apellido"
                    id="apellido"
                    v-model="practicante.apellido"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.apellido !== null"
                  >
                    {{ erroresPracticante.apellido[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    v-model="practicante.email"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.email !== null"
                  >
                    {{ erroresPracticante.email[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="dni">DNI</label>
                  <input
                    type="text"
                    name="dni"
                    id="dni"
                    v-model="practicante.dni"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.dni !== null"
                  >
                    {{ erroresPracticante.dni[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="direccion_1">Dirección</label>
                  <input
                    type="text"
                    name="direccion_1"
                    id="direccion_1"
                    v-model="practicante.direccion_1"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.direccion_1 !== null"
                  >
                    {{ erroresPracticante.direccion_1[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="ciudad_1">Ciudad / Localidad / Barrio</label>
                  <input
                    type="text"
                    name="ciudad_1"
                    id="ciudad_1"
                    v-model="practicante.ciudad_1"
                    :disabled="cargando"
                  >
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.ciudad_1 !== null"
                  >
                    {{ erroresPracticante.ciudad_1[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="provincia_id_1">Provincia</label>
                  <select
                    id="provincia_id_1"
                    name="provincia_id_1"
                    class="form minimal"
                    v-model.number="practicante.provincia_id_1"
                    :disabled="cargando"
                  >
                    <option
                      v-for="provincia in provincias"
                      :value="provincia.provincia_id"
                      :key="provincia.provincia_id"
                    >
                      {{ provincia.nombre }}
                    </option>
                  </select>
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.provincia_id_1 !== null"
                  >
                    {{ erroresPracticante.provincia_id_1[0] }}
                  </div>
                </div>
                <div class="form_section_seminario_no_socio">
                  <label for="pais_id_1">País</label>
                  <select
                    id="pais_id_1"
                    name="pais_id_1"
                    class="form minimal"
                    v-model.number="practicante.pais_id_1"
                    :disabled="cargando"
                  >
                    <option
                      v-for="pais in paises"
                      :value="pais.pais_id"
                      :key="pais.pais_id"
                    >
                      {{ pais.nombre }}
                    </option>
                  </select>
                  <div
                  class="form_mensaje_error_no_socio"
                    v-if="erroresPracticante.pais_id_1 !== null"
                  >
                    {{ erroresPracticante.pais_id_1[0] }}
                  </div>
                </div>
                <button type="submit" class="boton_guardar">Pagar Sin Descuento <br>($ {{ dotsCadaTres(parseFloat(this.precioCarritoNoSocio).toFixed(2).replace(/\.00$/, '')) }})</button>
              </form>
            </div>
          </div>
          <div
            v-else
            class="contenedor_carrito_vacio"
          >
            <h3>El carrito se encuentra vacío</h3>
            <img src="@/assets/carrito_vacio.png" alt="Carrito Vacío">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import paisesService from '../services/paises'
import provinciasService from '../services/provincias'
import productosService from '../services/productos'
import personasService from '../services/personas'
import { PATH_IMG } from '../constants'
import store from './../store'
const mercadopago = require('mercadopago')
export default {
  name: 'MaterialDidactico',
  components: {
  },
  data: function () {
    return {
      carrito: [],
      productos: [],
      variacionesProducto: {
        talle_id: null,
        talle_nombre: null,
        color_nombre: null,
        color_id: null
      },
      paises: [],
      provincias: [],
      duplicado: false,
      cantidadTotalCarrito: null,
      precioCarritoSocio: null,
      precioCarritoNoSocio: null,
      mostrarContenedorCarrito: false,
      mostarSiEsCarritoNoVacio: false,
      mostrarFormNoSocio: false,
      mostarSiEsSocio: false,
      mostrarElegirColor: false,
      pagoSocioAlDia: false,
      pagoSocioDeudor: false,
      socio: {
        persona_id: null,
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        direccion: null,
        provincia: null
      },
      errores: {
        dni: null
      },
      practicante: {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        perfil_id: 5,
        reincidente: 'no',
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null
      },
      erroresPracticante: {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null
      },
      linkMercadoPago: {
        link: null,
        textoCarrito: null,
        precio: null,
        descuento: null,
        socio: null
      },
      cargando: false,
      store
    }
  },
  methods: {
    cerrarTodosLosColores () {
      // this.mostrarElegirColor = false
      const matches = document.querySelectorAll('.confirmation_talle')
      for (let i = 0; i < matches.length; i++) {
        matches[i].style.display = 'none'
      }
    },
    seleccionarColor (productoId, colorId, colorNombre) {
      console.log(productoId)
      console.log(colorId)
      console.log(colorNombre)
      const botonesTalles = document.querySelectorAll('.boton_color')
      for (let i = 0; i < botonesTalles.length; i++) {
        botonesTalles[i].classList.remove('boton_color_selected')
      }
      document.querySelector('.boton_color_' + productoId + '_' + colorId + '_' + colorNombre).classList.add('boton_color_selected')
      this.variacionesProducto.color_id = colorId
      this.variacionesProducto.color_nombre = colorNombre
    },
    seleccionarTalle (productoId, talleId, talleNombre) {
      const botonesTalles = document.querySelectorAll('.boton_talle')
      for (let i = 0; i < botonesTalles.length; i++) {
        botonesTalles[i].classList.remove('boton_talle_selected')
      }
      this.mostrarElegirColor = true
      this.cerrarTodosLosColores()
      const confirmation = document.querySelector('.confirmation_talle_' + productoId + '_' + talleNombre)
      confirmation.style.display = 'flex'

      document.querySelector('.boton_talle_' + productoId + '_' + talleNombre).classList.add('boton_talle_selected')
      console.log(confirmation)
      this.variacionesProducto.talle_id = talleId
      this.variacionesProducto.talle_nombre = talleNombre
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
      // Pongo el body sin scroll
      const body = document.body
      body.style.overflow = 'hidden'
      document.addEventListener('mouseup', this.closeModalTallesOnClickOutside)
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
      // saco el overflow del body
      const body = document.body
      body.style.overflow = 'auto'
      document.removeEventListener('mouseup', this.closeModalTallesOnClickOutside)
      this.variacionesProducto.color_id = null
      this.variacionesProducto.color_nombre = null
      this.cerrarTodosLosColores()
      this.mostrarElegirColor = false
      const botonesTalles = document.querySelectorAll('.boton_talle')
      for (let i = 0; i < botonesTalles.length; i++) {
        botonesTalles[i].classList.remove('boton_talle_selected')
      }
      const botonesColores = document.querySelectorAll('.boton_color')
      for (let i = 0; i < botonesTalles.length; i++) {
        botonesColores[i].classList.remove('boton_color_selected')
      }
    },
    closeModalTallesOnClickOutside (id) {
      console.log(id)
      const modal2 = this.$refs.modalRefTalleColor
      console.log(modal2[0])
      if (!modal2.contains(event.target)) {
        console.log('click afuera')
        document.removeEventListener('mouseup', this.closeModalTallesOnClickOutside)
        this.cerrarModal(id)
        // saco el overflow del body
        const body = document.body
        body.style.overflow = 'auto'
      } else {
        console.log('click adentro')
      }
    },
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    pagoCarritoNoSocio (practicante) {
      console.log(practicante)
      if (this.cargando) {
        return null
      }
      this.erroresPracticante = {
        nombre: null,
        apellido: null,
        email: null,
        dni: null,
        ciudad_1: null,
        direccion_1: null,
        provincia_id_1: null,
        pais_id_1: null
      }
      personasService
        .asociarse(practicante)
        .then(respuesta => {
          if (respuesta.success) {
            console.log('siiii')
            this.socio.persona_id = this.practicante.persona_id
            this.socio.nombre = this.practicante.nombre
            this.socio.apellido = this.practicante.apellido
            this.socio.email = this.practicante.email
            this.socio.direccion_1 = this.practicante.direccion_1
            this.socio.ciudad_1 = this.practicante.ciudad_1
            this.socio.provincia_id_1 = this.practicante.provincia_id_1
            this.socio.pais_id_1 = this.practicante.pais_id_1
            this.socio.dni = this.practicante.dni
            this.pagarCarrito('no')
          } else {
            console.log('noooo')
            this.erroresPracticante = {
              nombre: null,
              apellido: null,
              email: null,
              dni: null,
              ciudad_1: null,
              direccion_1: null,
              provincia_id_1: null,
              pais_id_1: null,
              ...respuesta.errors
            }
          }
        })
    },
    pagarCarrito (id) {
      if (id === 'si') {
        this.linkMercadoPago.precio = this.precioCarritoSocio
        this.linkMercadoPago.descuento = 1 // Descuento SI
      } else {
        this.linkMercadoPago.precio = this.precioCarritoNoSocio
        this.linkMercadoPago.descuento = 2 // Descuento NO
      }
      this.callMercadoPago()
    },
    callMercadoPago () {
      const preference = {
        back_urls: {
          failure: 'https://portal.asociacioniyengar.com.ar/#/pago-carrito-rechazado',
          success: 'https://portal.asociacioniyengar.com.ar/#/pago-carrito-exitoso'
        },
        items: [
          {
            title: this.linkMercadoPago.textoCarrito,
            unit_price: this.linkMercadoPago.precio,
            quantity: 1
          }
        ],
        metadata: {
          persona_id: this.socio.persona_id,
          nombre: this.socio.nombre,
          apellido: this.socio.apellido,
          email: this.socio.email,
          direccion_1: this.socio.direccion_1,
          ciudad_1: this.socio.ciudad_1,
          provincia_id_1: this.socio.provincia_id_1,
          pais_id_1: this.socio.pais_id_1,
          dni: this.socio.dni,
          concepto_id: 6,
          forma_de_pago_id: 3,
          moneda_id: 1,
          pago_concepto: 'Material Didáctico',
          pago_descuento: this.linkMercadoPago.descuento,
          descripcion: this.linkMercadoPago.textoCarrito,
          socio: this.linkMercadoPago.socio,
          // precio_individual: Number(respuesta[respuesta.length - 1].precio_individual),
          precio_total: Number(this.linkMercadoPago.precio),
          carrito: Array(this.carrito)
          // descripcion: 'Pago Cuota Social ' + new Date().getFullYear(),
          // year: new Date().getFullYear()
        },
        payer: {
          name: this.socio.nombre,
          surname: this.socio.apellido
        },
        notification_url: 'https://api.asociacioniyengar.com.ar/api/webhooks-carrito',
        // notification_url: 'https://6a4f-181-165-81-31.ngrok-free.app/AAYI_API/api/public/api/webhooks-carrito',
        binary_mode: true,
        auto_return: 'approved',
        external_reference: this.socio.nombre + ' ' + this.socio.apellido + ' - ' + new Date().getTime().toString(),
        statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
      }
      mercadopago.configure({
        access_token: 'APP_USR-6447832264591121-032711-ad6690b0c38d8e10440c46daab8b7204-1323684790'
        // Credenciales de Prueba
        // access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
      })
      mercadopago.preferences
        .create(preference)
        .then(response => {
          console.log('mercadopago: ', response.body)
          console.log(new Date().getTime())
          // console.log(this.linkMercadoPago.link)
          this.linkMercadoPago.link = response.body.init_point
          // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
          window.location.href = this.linkMercadoPago.link
        })
        .catch(function (error) {
          console.log('MP ERROR!!!!!')
          console.log(error)
        })
    },
    soySocio (id) {
      if (id === 'si') {
        this.mostarSiEsSocio = true
        this.mostrarFormNoSocio = false
        // setTimeout(() => this.mostarSiEsSocio = true, 500)
        setTimeout(() => {
          const valor = document.querySelector('.form_soy_socio').scrollHeight
          console.log(valor)
          // document.querySelector('.listado_carrito').scrollTop = valor
          document.querySelector('.listado_carrito').scrollTo(0, document.body.scrollHeight)
        }, 100)
      } else {
        this.mostarSiEsSocio = false
        this.mostrarFormNoSocio = true
        setTimeout(() => {
          const valor = document.querySelector('.contenedor_form_carrito').scrollHeight
          console.log(valor)
          console.log(document.body.scrollHeight)
          document.querySelector('.listado_carrito').scrollTo(0, document.body.scrollHeight)
        }, 100)
      }
    },
    abrirCarrito () {
      this.mostrarContenedorCarrito = true
      const body = document.body
      body.style.overflow = 'hidden'
      document.addEventListener('mouseup', this.closeModalOnClickOutside)
    },
    cerrarCarrito () {
      this.mostrarContenedorCarrito = false
      this.mostarSiEsSocio = false
      this.mostrarFormNoSocio = false
      this.pagoSocioDeudor = false
      this.pagoSocioAlDia = false
      this.socio.dni = null
      this.practicante.dni = null
      this.errores = {
        dni: null
      }
      const body = document.body
      body.style.overflow = 'auto'
      document.removeEventListener('mouseup', this.closeModalOnClickOutside)
    },
    closeModalOnClickOutside (id) {
      console.log(id)
      const modal = this.$refs.modalRef
      if (!modal.contains(event.target)) {
        console.log('click afuera')
        document.removeEventListener('mouseup', this.closeModalOnClickOutside)
        this.cerrarCarrito()
        this.mostarSiEsSocio = false
        // saco el overflow del body
        const body = document.body
        body.style.overflow = 'auto'
      } else {
        console.log('click adentro')
      }
    },
    validarSocio (socio) {
      console.log('qqqq')
      console.log(socio)
      if (this.cargando) {
        return null
      }
      this.errores = {
        dni: null
      }
      this.cargando = true
      personasService
        .validarSocio(socio)
        .then(respuesta => {
          this.cargando = false
          if (respuesta.success) {
            console.log('SI sos socio')
            console.log(respuesta.data[0])
            console.log(respuesta.data)
            this.mostrarFormNoSocio = false
            this.socio = {
              persona_id: respuesta.data[0].persona_id,
              nombre: respuesta.data[0].nombre,
              apellido: respuesta.data[0].apellido,
              email: respuesta.data[0].email,
              dni: respuesta.data[0].dni,
              direccion_1: respuesta.data[0].direccion_1,
              ciudad_1: respuesta.data[0].ciudad_1,
              provincia_id_1: respuesta.data[0].provincia_id_1,
              pais_id_1: respuesta.data[0].pais_id_1
            }
            this.errores = {
              dni: null
            }
            if (respuesta.pago) {
              console.log('La cuota esta paga')
              this.pagoSocioAlDia = true
            } else {
              console.log('La cuota NO esta paga')
              this.pagoSocioDeudor = true
            }
            this.linkMercadoPago.socio = 1 // sos socio
            setTimeout(() => {
              const valor = document.querySelector('.form_soy_socio')
              console.log(valor)
              valor.scrollIntoView()
              // console.log(document.body.scrollHeight)
              // document.querySelector('.listado_carrito').scrollTo(0, valor)
            }, 100)
          } else {
            this.pagoSocioDeudor = false
            this.pagoSocioAlDia = false
            console.log('NO sos socio')
            if (respuesta.info === 'primera') {
              this.errores = {
                dni: ['El DNI ingresado no figura en nuestros registros']
              }
              this.practicante.dni = this.socio.dni
              this.linkMercadoPago.socio = 2 // NO sos socio primer vez
            }
            if (respuesta.info === 'reincidente') {
              this.linkMercadoPago.socio = 3 // NO sos socio reincidente
              console.log(respuesta.data[0])
              this.practicante.persona_id = respuesta.data[0].persona_id
              this.practicante.nombre = respuesta.data[0].nombre
              this.practicante.apellido = respuesta.data[0].apellido
              this.practicante.email = respuesta.data[0].email
              this.practicante.dni = respuesta.data[0].dni
              this.practicante.direccion_1 = respuesta.data[0].direccion_1
              this.practicante.ciudad_1 = respuesta.data[0].ciudad_1
              this.practicante.provincia_id_1 = respuesta.data[0].provincia_id_1
              this.practicante.pais_id_1 = respuesta.data[0].pais_id_1
              this.practicante.reincidente = 'si'
            }
            this.mostrarFormNoSocio = true
            setTimeout(() => {
              const valor = document.querySelector('.form_soy_socio')
              console.log(valor)
              valor.scrollIntoView()
              // console.log(document.body.scrollHeight)
              // document.querySelector('.listado_carrito').scrollTo(0, valor)
            }, 100)
          }
        })
    },
    BotonAgregar (id, nombre, precioSocio, precioNoSocio, stock, imagen, costo, talleId, talleNombre, colorId, colorNombre) {
      this.AgregarCarrito(id, nombre, precioSocio, precioNoSocio, stock, imagen, costo, talleId, talleNombre, colorId, colorNombre)
      this.ContarCarrito()
    },
    AgregarCarrito (id, nombre, precioSocio, precioNoSocio, stock, imagen, costo, talleId, talleNombre, colorId, colorNombre) {
      console.log(id)
      console.log(talleId)
      console.log(colorId)
      this.cerrarModal(id)
      this.duplicado = false
      if (this.carrito.length === 0) {
        console.log('primer ingreso')
        const carritoItem = {}
        carritoItem.producto_id = id
        carritoItem.cantidad = 1
        carritoItem.precio_socio = precioSocio
        carritoItem.precio_total_socio = precioSocio * 1
        carritoItem.precio_no_socio = precioNoSocio
        carritoItem.precio_total_no_socio = precioNoSocio * 1
        carritoItem.precio_de_costo = costo
        carritoItem.nombre = nombre
        carritoItem.stock = stock
        carritoItem.imagen = imagen
        carritoItem.talle_id = talleId
        carritoItem.talle_nombre = talleNombre
        carritoItem.color_id = colorId
        carritoItem.color_nombre = colorNombre
        this.carrito.push(carritoItem)
      } else {
        for (var i = 0; i < this.carrito.length; i++) {
          if (this.carrito[i].producto_id === id) {
            if (this.carrito[i].talle_id === talleId && this.carrito[i].color_id === colorId) {
              this.carrito[i].cantidad++
              this.carrito[i].precio_total_socio = this.carrito[i].precio_socio * this.carrito[i].cantidad
              this.carrito[i].precio_total_no_socio = this.carrito[i].precio_no_socio * this.carrito[i].cantidad
              this.duplicado = true
              console.log('11122222')
            }
          }
        }
        if (!this.duplicado) {
          console.log('NO SOY DUPLICADO' + id)
          const carritoItem = {}
          carritoItem.producto_id = id
          carritoItem.cantidad = 1
          carritoItem.precio_socio = precioSocio
          carritoItem.precio_total_socio = precioSocio * 1
          carritoItem.precio_no_socio = precioNoSocio
          carritoItem.precio_total_no_socio = precioNoSocio * 1
          carritoItem.precio_de_costo = costo
          carritoItem.nombre = nombre
          carritoItem.stock = stock
          carritoItem.imagen = imagen
          carritoItem.talle_id = talleId
          carritoItem.talle_nombre = talleNombre
          carritoItem.color_id = colorId
          carritoItem.color_nombre = colorNombre
          this.carrito.push(carritoItem)
        }
      }
      // this.store.carrito = this.carrito
      localStorage.setItem('carrito', JSON.stringify(this.carrito))
      this.mostarSiEsCarritoNoVacio = true
      // Borro las variaciones
      this.variacionesProducto.talle_id = null
      this.variacionesProducto.talle_nombre = null
      this.variacionesProducto.color_id = null
      this.variacionesProducto.color_nombre = null
    },
    Decrementar (id) {
      for (let i = 0; i < this.carrito.length; i++) {
        if (this.carrito[i].producto_id === id && this.carrito[i].cantidad !== 1) {
          this.carrito[i].cantidad -= 1
          this.carrito[i].precio_total_socio = this.carrito[i].precio_socio * this.carrito[i].cantidad
          this.carrito[i].precio_total_no_socio = this.carrito[i].precio_no_socio * this.carrito[i].cantidad
          this.ContarCarrito()
          const botonMenos = event.currentTarget
          const botonMas = event.currentTarget.nextElementSibling
          if (this.carrito[i].cantidad === 1) {
            botonMenos.disabled = true
          } else {
            botonMenos.disabled = false
          }
          if (this.carrito[i].cantidad === this.carrito[i].stock) {
            botonMas.disabled = true
          } else {
            botonMas.disabled = false
          }
        }
      }
      localStorage.setItem('carrito', JSON.stringify(this.carrito))
      this.ContarCarrito()
      console.log('Decrementar: ' + id)
    },
    Incrementar (id, event) {
      for (let i = 0; i < this.carrito.length; i++) {
        if (this.carrito[i].producto_id === id) {
          if (this.carrito[i].cantidad < this.carrito[i].stock) {
            this.carrito[i].cantidad++
            this.carrito[i].precio_total_socio = this.carrito[i].precio_socio * this.carrito[i].cantidad
            this.carrito[i].precio_total_no_socio = this.carrito[i].precio_no_socio * this.carrito[i].cantidad
          }
          // this.carrito[i].cantidad += 1
          // this.carrito[i].precio_total_socio = this.carrito[i].precio_socio * this.carrito[i].cantidad
          // this.carrito[i].precio_total_no_socio = this.carrito[i].precio_no_socio * this.carrito[i].cantidad
          this.ContarCarrito()
          const botonMas = event.currentTarget
          const botonMenos = event.currentTarget.previousElementSibling
          if (this.carrito[i].cantidad === this.carrito[i].stock) {
            botonMas.disabled = true
          } else {
            botonMas.disabled = false
          }
          if (this.carrito[i].cantidad === 1) {
            botonMenos.disabled = true
          } else {
            botonMenos.disabled = false
          }
        }
      }
      localStorage.setItem('carrito', JSON.stringify(this.carrito))
      this.ContarCarrito()
      console.log('Incrementar: ' + id)
    },
    EliminarProducto (id) {
      for (let i = 0; i < this.carrito.length; i++) {
        if (this.carrito[i].producto_id === id) {
          this.carrito.splice(i, 1)
          this.ContarCarrito()
        }
      }
      localStorage.setItem('carrito', JSON.stringify(this.carrito))
      this.ContarCarrito()
    },
    ContarCarrito () {
      let sumProd = 0
      let totPrecioSocio = 0
      let totPrecioNoSocio = 0
      console.log(localStorage.getItem('carrito'))
      if (localStorage.getItem('carrito') !== null) {
        const cartData = JSON.parse(localStorage.getItem('carrito'))
        console.log('contando carrito: ' + cartData.length)
        for (let i = 0; i < cartData.length; i++) {
          sumProd += cartData[i].cantidad
          totPrecioSocio += cartData[i].precio_socio * cartData[i].cantidad
          totPrecioNoSocio += cartData[i].precio_no_socio * cartData[i].cantidad
        }
      }
      this.cantidadTotalCarrito = sumProd
      this.precioCarritoSocio = totPrecioSocio
      this.precioCarritoNoSocio = totPrecioNoSocio
      console.log('Cantidad Productos en Carrito: ' + sumProd)
      console.log('Precio Total Socio en Carrito: ' + totPrecioSocio)
      console.log('Precio Total NO Socio en Carrito: ' + totPrecioNoSocio)
      this.testoDescripcionCarrito()
    },
    VaciarCarrito () {
      this.carrito = []
      this.mostarSiEsCarritoNoVacio = false
      this.mostrarFormNoSocio = false
      this.pagoSocioDeudor = false
      this.pagoSocioAlDia = false
      // this.precioCarritoSocio = null
      // this.precioCarritoNoSocio = null
      // this.cantidadTotalCarrito = null
      localStorage.removeItem('carrito')
      this.ContarCarrito()
      // this.socio.dni = null
      console.log(this.carrito)
    },
    testoDescripcionCarrito () {
      console.log('32322377777')
      let text = null
      for (let i = 0; i < this.carrito.length; i++) {
        if (i === 0) {
          text = this.carrito[i].nombre + ' ' + ('(#' + this.carrito[i].cantidad + ')')
        } else {
          text += ' - ' + this.carrito[i].nombre + ' ' + ('(#' + this.carrito[i].cantidad + ')')
        }
      }
      this.linkMercadoPago.textoCarrito = text
    },
    imagenRuta (data) {
      return `${PATH_IMG}/${data}`
    }
  },
  mounted: function () {
    const body = document.body
    body.style.overflow = 'auto'
    if (!localStorage.getItem('carrito')) {
      console.log('No habia carrito')
      localStorage.setItem('carrito', JSON.stringify([]))
      this.mostarSiEsCarritoNoVacio = false
    } else {
      this.carrito = JSON.parse(localStorage.getItem('carrito'))
      console.log(this.carrito)
      this.testoDescripcionCarrito(this.carrito)
      this.mostarSiEsCarritoNoVacio = true
    }
    this.ContarCarrito()
    // const carrito = localStorage.getItem('cart')
    // this.carrito = carrito ? JSON.parse(carrito) : []
    console.log('wwweee22222')
    document.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        console.log('apretaste ESC')
        this.cerrarCarrito()
        this.mostarSiEsSocio = false
        this.mostrarFormNoSocio = false
        this.pagoSocioDeudor = false
        this.pagoSocioAlDia = false
        this.socio.dni = null
        this.practicante.dni = null
        this.errores = {
          dni: null
        }
      }
    })
    productosService
      .traerProductosTodos()
      .then(respuesta => {
        this.productos = respuesta
        console.log('Productos:', respuesta)
      })
    paisesService
      .traerPaisesTodos()
      .then(respuesta => {
        // this.cargando = false
        this.paises = respuesta
        console.log('Paises:', respuesta)
      })
    provinciasService
      .traerProvinciasTodas()
      .then(respuesta => {
        // this.cargando = false
        this.provincias = respuesta
        console.log('Provincias:', respuesta)
      })
  }
}
</script>

<style>

</style>
