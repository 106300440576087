<template>
  <div class="contenedor-cpanel">
    <div class="contenedor-titulo">
      <h1>Convención 2025 Merchandising</h1>
      <div class="barra"></div>
    </div>
    <section class="contenedor-c">
      <Merchandising2025Lista
      ></Merchandising2025Lista>
    </section>
  </div>
</template>

<script>
import Merchandising2025Lista from '../components/Merchandising2025Lista.vue'
export default {
  name: 'Convención2025Merchandising',
  components: {
    Merchandising2025Lista
  },
  data: function () {
    return {
    }
  }
}
</script>

<style>

</style>
