<template>
  <div class="container-listado-socios">
    <div class="contenedor-buscador-tabla-botonera">
      <div>
        <BaseLoader
          v-if="cargando"
        />
        <div v-else>
          <div class="contenedor_socios_lista" >
            <h2>Venta de Remeras</h2>
            <button
              class="boton_exportar"
              @click="exportData(1)"
            >
              Exportar Remeras a Excel
            </button>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Fecha</th>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Cantidad</th>
              </thead>
              <tbody>
                <RemerasListaItem
                  v-for="venta in ventaRemeras"
                  :venta="venta"
                  :key="venta.merchandising_id"
                ></RemerasListaItem>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <BaseLoader
          v-if="cargando"
        />
        <div v-else>
          <div class="contenedor_socios_lista" >
            <h2>Venta de Kits</h2>
            <button
              class="boton_exportar"
              @click="exportData(2)"
            >
              Exportar Kits a Excel
            </button>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Fecha</th>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Cantidad</th>
              </thead>
              <tbody>
                <KitsListaItem
                  v-for="venta in ventaKits"
                  :venta="venta"
                  :key="venta.merchandising_id"
                ></KitsListaItem>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div>
        <BaseLoader
          v-if="cargando"
        />
        <div v-else>
          <div class="contenedor_socios_lista" >
            <h2>Almuerzos</h2>
            <button
              class="boton_exportar"
              @click="exportData(3)"
            >
              Exportar Almuerzos a Excel
            </button>
            <h3>Día 1 - Jueves 1 de Mayo</h3>
            <h4>Menú 1</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia1Menu1"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h4>Menú 2</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia1Menu2"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h3>Día 2 - Viernes 2 de Mayo</h3>
            <h4>Menú 1</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia2Menu1"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h4>Menú 2</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia2Menu2"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h3>Día 3 - Sábado 3 de Mayo</h3>
            <h4>Menú 1</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia3Menu1"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h4>Menú 2</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia3Menu2"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h3>Día 4 - Domingo 4 de Mayo</h3>
            <h4>Menú 1</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia4Menu1"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
            <h4>Menú 2</h4>
            <table id="tabla-socio-lista">
              <thead>
                <th class="column_1">Nombre</th>
                <th class="column_1">Apellido</th>
                <th class="column_1">Email</th>
                <th class="column_1">Celular</th>
                <th class="column_1">Menú</th>
              </thead>
              <tbody>
                <AlmuerzosListaItem
                  v-for="venta in ventaAlmuerzosDia4Menu2"
                  :venta="venta"
                  :key="venta.persona_id"
                ></AlmuerzosListaItem>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ventasService from '../services/ventas'
import RemerasListaItem from '../components/RemerasListaItem.vue'
import KitsListaItem from '../components/KitsListaItem.vue'
import AlmuerzosListaItem from '../components/AlmuerzosListaItem.vue'
import asociadoService from '../services/asociado'
import personasService from '../services/personas'
import BaseLoader from '../components/BaseLoader.vue'
const XLSX = require('xlsx')
export default {
  name: 'Merchandising2025Lista',
  components: {
    RemerasListaItem,
    KitsListaItem,
    AlmuerzosListaItem,
    BaseLoader
  },
  data: function () {
    return {
      selected: {
        perfil_id: [],
        certificacion_id: [],
        socio_id: [],
        condicion_id: [],
        tipo_id: [],
        web_id: [],
        estudio_nombre_1: [],
        dni: [],
        provincia_id_1: [],
        toma_clase: []
      },
      archivo: [],
      archivo2: [],
      archivoVentaAlmuerzosDia1Menu1: [],
      archivoVentaAlmuerzosDia1Menu2: [],
      archivoVentaAlmuerzosDia2Menu1: [],
      archivoVentaAlmuerzosDia2Menu2: [],
      archivoVentaAlmuerzosDia3Menu1: [],
      archivoVentaAlmuerzosDia3Menu2: [],
      archivoVentaAlmuerzosDia4Menu1: [],
      archivoVentaAlmuerzosDia4Menu2: [],
      socios: ['q'],
      ventaRemeras: [],
      ventaKits: [],
      ventaAlmuerzosDia1Menu1: [],
      ventaAlmuerzosDia1Menu2: [],
      ventaAlmuerzosDia2Menu1: [],
      ventaAlmuerzosDia2Menu2: [],
      ventaAlmuerzosDia3Menu1: [],
      ventaAlmuerzosDia3Menu2: [],
      ventaAlmuerzosDia4Menu1: [],
      ventaAlmuerzosDia4Menu2: [],
      certificaciones: [],
      estados: [],
      condiciones: [],
      tipos: [],
      web: [],
      paginado: [],
      mostrarLastPageBullets: true,
      busqueda: null,
      cargando: false,
      pageNumber: 1,
      currentPage: 1,
      pageCount: 0,
      listData: {
        type: Array,
        required: true
      },
      sizePaginado: 20,
      sizeBulletPaginado: 5
    }
  },
  methods: {
    fechaFormato (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    exportData (txt) {
      // const data = this.archivo
      // const workSheet = XLSX.utils.json_to_sheet(data)
      // const workBook = XLSX.utils.book_new()
      if (txt === 1) {
        const data = this.archivo
        const workSheet = XLSX.utils.json_to_sheet(data)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Ventas_Remeras_Convención_2025')
        XLSX.writeFile(workBook, 'Ventas_Remeras_Convención_2025.xlsx')
      }
      if (txt === 2) {
        const data = this.archivo2
        const workSheet = XLSX.utils.json_to_sheet(data)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, 'Ventas_Kits_Convención_2025')
        XLSX.writeFile(workBook, 'Ventas_Kits_Convención_2025.xlsx')
      }
      if (txt === 3) {
        const dataDia1Menu1 = this.archivoVentaAlmuerzosDia1Menu1
        const dataDia1Menu2 = this.archivoVentaAlmuerzosDia1Menu2
        const dataDia2Menu1 = this.archivoVentaAlmuerzosDia2Menu1
        const dataDia2Menu2 = this.archivoVentaAlmuerzosDia2Menu2
        const dataDia3Menu1 = this.archivoVentaAlmuerzosDia3Menu1
        const dataDia3Menu2 = this.archivoVentaAlmuerzosDia3Menu2
        const dataDia4Menu1 = this.archivoVentaAlmuerzosDia4Menu1
        const dataDia4Menu2 = this.archivoVentaAlmuerzosDia4Menu2
        const workSheet1 = XLSX.utils.json_to_sheet(dataDia1Menu1)
        const workSheet2 = XLSX.utils.json_to_sheet(dataDia1Menu2)
        const workSheet3 = XLSX.utils.json_to_sheet(dataDia2Menu1)
        const workSheet4 = XLSX.utils.json_to_sheet(dataDia2Menu2)
        const workSheet5 = XLSX.utils.json_to_sheet(dataDia3Menu1)
        const workSheet6 = XLSX.utils.json_to_sheet(dataDia3Menu2)
        const workSheet7 = XLSX.utils.json_to_sheet(dataDia4Menu1)
        const workSheet8 = XLSX.utils.json_to_sheet(dataDia4Menu2)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet1, 'Ventas_Almuerzos_Dia1_Menu1')
        XLSX.utils.book_append_sheet(workBook, workSheet2, 'Ventas_Almuerzos_Dia1_Menu2')
        XLSX.utils.book_append_sheet(workBook, workSheet3, 'Ventas_Almuerzos_Dia2_Menu1')
        XLSX.utils.book_append_sheet(workBook, workSheet4, 'Ventas_Almuerzos_Dia2_Menu2')
        XLSX.utils.book_append_sheet(workBook, workSheet5, 'Ventas_Almuerzos_Dia3_Menu1')
        XLSX.utils.book_append_sheet(workBook, workSheet6, 'Ventas_Almuerzos_Dia3_Menu2')
        XLSX.utils.book_append_sheet(workBook, workSheet7, 'Ventas_Almuerzos_Dia4_Menu1')
        XLSX.utils.book_append_sheet(workBook, workSheet8, 'Ventas_Almuerzos_Dia4_Menu2')
        XLSX.writeFile(workBook, 'Ventas_Almuerzos_Convención_2025.xlsx')
      }
    },
    traerSociosPorFiltro () {
      console.log('ewerwerewrew')
      console.log(this.selected)
      this.busqueda = null
      this.cargando = true
      personasService
        .traerSociosPorFiltro(JSON.stringify(this.selected))
        .then(respuesta => {
          this.cargando = false
          this.currentPage = 1
          this.socios = []
          // this.socios = respuesta
          console.log('INFO POR FILTRO', respuesta)
          this.socios = respuesta
          this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
          // this.exportarExcel(respuesta)
        })
    },
    nextPage () {
      this.currentPage++
      console.log(this.currentPage)
      this.sosUltimaPagina()
      // this.sociosPaginado()
    },
    prevPage () {
      this.currentPage--
      console.log(this.currentPage)
      this.sosUltimaPagina()
      // this.sociosPaginado()
    },
    goToPage (page) {
      this.currentPage = page
      console.log(page)
      this.sosUltimaPagina()
    },
    firstPage () {
      this.currentPage = 1
      this.sosUltimaPagina()
    },
    lastPage () {
      this.currentPage = this.pageCount
      this.sosUltimaPagina()
    },
    sosUltimaPagina () {
      console.log('2222222')
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      // const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        this.mostrarLastPageBullets = false
      } else {
        this.mostrarLastPageBullets = true
      }
    },
    exportarExcel (data) {
      console.log(data)
      this.archivo = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        obj.Fecha = this.fechaFormato(data[j].fecha_de_venta)
        obj.Nombre = data[j].persona.nombre
        obj.Apellido = data[j].persona.apellido
        obj.Email = data[j].persona.email
        obj.Celular = data[j].persona.celular
        obj.Cantidad = data[j].cantidad
        this.archivo.push(obj)
      }
    },
    exportarExcel2 (data) {
      console.log(data)
      this.archivo2 = []
      for (var j = 0; j < data.length; j++) {
        const obj = {}
        obj.Fecha = this.fechaFormato(data[j].fecha_de_venta)
        obj.Nombre = data[j].persona.nombre
        obj.Apellido = data[j].persona.apellido
        obj.Email = data[j].persona.email
        obj.Celular = data[j].persona.celular
        obj.Cantidad = data[j].cantidad
        this.archivo2.push(obj)
      }
    },
    exportarExcelAlmuerzo (data) {
      console.log(data)
      this.archivoVentaAlmuerzosDia1Menu1 = []
      this.archivoVentaAlmuerzosDia1Menu2 = []
      this.archivoVentaAlmuerzosDia2Menu1 = []
      this.archivoVentaAlmuerzosDia2Menu2 = []
      this.archivoVentaAlmuerzosDia3Menu1 = []
      this.archivoVentaAlmuerzosDia3Menu2 = []
      this.archivoVentaAlmuerzosDia4Menu1 = []
      this.archivoVentaAlmuerzosDia4Menu2 = []
      for (var j = 0; j < data.length; j++) {
        for (var k = 0; k < data[j].length; k++) {
          for (var m = 0; m < data[j][k].length; m++) {
            const obj = {}
            obj.Nombre = data[j][k][m].persona_nombre
            obj.Apellido = data[j][k][m].persona_apellido
            obj.Email = data[j][k][m].persona_email
            obj.Celular = data[j][k][m].persona_celular
            obj.Menu = data[j][k][m].menu
            if (j === 0 && k === 0) {
              this.archivoVentaAlmuerzosDia1Menu1.push(obj)
            }
            if (j === 0 && k === 1) {
              this.archivoVentaAlmuerzosDia1Menu2.push(obj)
            }
            if (j === 1 && k === 0) {
              this.archivoVentaAlmuerzosDia2Menu1.push(obj)
            }
            if (j === 1 && k === 1) {
              this.archivoVentaAlmuerzosDia2Menu2.push(obj)
            }
            if (j === 2 && k === 0) {
              this.archivoVentaAlmuerzosDia3Menu1.push(obj)
            }
            if (j === 2 && k === 1) {
              this.archivoVentaAlmuerzosDia3Menu2.push(obj)
            }
            if (j === 3 && k === 0) {
              this.archivoVentaAlmuerzosDia4Menu1.push(obj)
            }
            if (j === 3 && k === 1) {
              this.archivoVentaAlmuerzosDia4Menu2.push(obj)
            }
          }
        }
      }
    },
    buscador (busqueda) {
      this.cargando = true
      this.selected.perfil_id = []
      this.selected.certificacion_id = []
      this.selected.socio_id = []
      this.selected.tipo_id = []
      this.selected.web_id = []
      this.selected.estudio_nombre_1 = []
      this.selected.dni = []
      this.selected.provincia_id_1 = []
      this.selected.toma_clase = []
      if (busqueda === '') {
        asociadoService
          .traerSociosTodos()
          .then(respuesta => {
            this.cargando = false
            this.currentPage = 1
            this.socios = []
            this.socios = respuesta
            console.log('Todas los socios por buscador', respuesta)
            console.log(this.socios)
            this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
            console.log(this.pageCount)
            // this.exportarExcel(respuesta)
          })
        return
      }
      asociadoService
        .traerSociosPorBuscador(busqueda)
        .then(respuesta => {
          this.cargando = false
          this.currentPage = 1
          this.socios = []
          this.socios = respuesta
          console.log('Todas los socios 2222', respuesta)
          console.log(this.socios)
          console.log(this.socios.length)
          this.pageCount = Math.ceil(this.socios.length / this.sizePaginado)
          console.log(this.pageCount)
          // this.exportarExcel(respuesta)
        })
    }
  },
  computed: {
    sociosPaginado: function () {
      const start = (this.currentPage - 1) * this.sizePaginado
      const end = start + this.sizePaginado
      return this.socios.slice(start, end)
    },
    bulletsPaginado: function () {
      const start = Math.floor((this.currentPage - 1) / this.sizeBulletPaginado) * this.sizeBulletPaginado
      const end = start + this.sizeBulletPaginado
      if (this.pageCount - start < this.sizeBulletPaginado) {
        return this.paginado.slice(this.pageCount - this.sizeBulletPaginado, this.pageCount)
      } else {
        return this.paginado.slice(start, end)
      }
    }
  },
  mounted: function () {
    this.cargando = true
    ventasService
      .traerVentasRemerasConvencion2025()
      .then(respuesta => {
        this.cargando = false
        console.log('Venta Remeras: ', respuesta)
        this.ventaRemeras = respuesta
        this.exportarExcel(respuesta)
      })
    ventasService
      .traerVentasKitsConvencion2025()
      .then(respuesta => {
        this.cargando = false
        console.log('Venta Kits: ', respuesta)
        this.ventaKits = respuesta
        this.exportarExcel2(respuesta)
      })
    ventasService
      .traerAlmuerzosConvencion2025()
      .then(respuesta => {
        this.cargando = false
        console.log('Venta Almuerzos: ', respuesta)
        this.ventaAlmuerzosDia1Menu1 = respuesta[0][0]
        this.ventaAlmuerzosDia1Menu2 = respuesta[0][1]
        this.ventaAlmuerzosDia2Menu1 = respuesta[1][0]
        this.ventaAlmuerzosDia2Menu2 = respuesta[1][1]
        this.ventaAlmuerzosDia3Menu1 = respuesta[2][0]
        this.ventaAlmuerzosDia3Menu2 = respuesta[2][1]
        this.ventaAlmuerzosDia4Menu1 = respuesta[3][0]
        this.ventaAlmuerzosDia4Menu2 = respuesta[3][1]
        this.exportarExcelAlmuerzo(respuesta)
      })
  }
}
</script>

<style>
.buscador {
/*  width: 500px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;*/
  background-image: url('../assets/lupa.png');
/*  background-repeat: no-repeat;
  background-position-y: center;
  text-indent: 40px;
  outline: none;
  margin: 20px 0;
  padding: 12px;*/
}

.buscador:focus {
/*  border-radius: 4px;
  border: 1px solid rgb(126,220,217);*/
  background-image: url('../assets/lupa_focus.png');
}
</style>
