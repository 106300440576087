import { API } from './../constants'

let provincias = null

const provinciasService = {
  /**
   * Retorna un array con todos los profesores
   *
   * @return {Promise<{}>}
   */
  async traerProvinciasTodas () {
    const fetchResponse = await fetch(`${API}/provincias`)
    const respuesta = await fetchResponse.json()
    provincias = respuesta.data
    return [...provincias]
  },
  async traerProvinciasArgentas () {
    const fetchResponse = await fetch(`${API}/provincias-argentas`)
    const respuesta = await fetchResponse.json()
    provincias = respuesta.data
    return [...provincias]
  }

}

export default provinciasService
