<template>
  <div class="contenedor-difusion">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Convención Anual 2025 - Abhijata Iyengar</h1>
        <div class="barra"></div>
      </div>
      <section class="contenedor-login">
        <div class="contenedor-difusion-imagen imagen-seminario-2025">
          <img src="@/assets/abhijata.png" alt="Convención Anual 2025 Abhijata Iyengar">
        </div>
        <div class="contenedor-seminario">

          <p>Con gran alegría, la comunidad argentina de Yoga Iyengar recibe nuevamente a Abhijata Iyengar en nuestro país.</p>

          <p>📍 <b>Lugar:</b> El encuentro se llevará a cabo en el Hangar de Canal 9, ubicado en Conde 51, CABA.</p>

          <p>📅 <b>Fechas:</b> Del jueves 1 al domingo 4 de mayo</p>

          <p>🕘 <b>Horarios de clases:</b> De 9:00 a 12:00 hs y de 14:00 a 17:00 hs</p>

          <p>🧘‍♂️ <b>Modalidad:</b> Presencial y Online</p>

          <h2>Programa General</h2>
          <p>📅 <b>Fechas:</b> 3 y 4 de mayo</p>
          <p>🔸 <b>Requisito:</b> Para practicantes con al menos 1 año de experiencia en el método Iyengar.</p>

          <p>💰 <b>Precio:</b></p>

          <ul class="lista_precios_seminario">
            <!-- <li>⚬ $225.000 (hasta el 20 de marzo precio promocional preventa)</li> -->
            <li>⚬ $258.750</li>
            <li>⚬ Pago en cuotas: 3 pagos de $86.250</li>
          </ul>

          <h2>Programa Completo</h2>
          <p>📅 <b>Fechas:</b> 1 al 4 de mayo</p>
          <p>🔸 <b>Requisito:</b> Para practicantes con al menos 4 años de experiencia en el método Iyengar.</p>

          <p>💰 <b>Precio:</b></p>

          <ul class="lista_precios_seminario">
            <!-- <li>⚬ $450.000 (hasta el 20 de marzo precio promocional preventa)</li> -->
            <li>⚬ $517.500</li>
            <li>⚬ Pago en cuotas: 3 pagos de $172.500</li>
          </ul>
          <div v-if="this.user.perfil_id == 1 || this.en_mentoria">
            <h2>Programa Profesores</h2>
            <p>📅 <b>Fechas:</b> 1 y 2 de mayo</p>
            <p>🔸 <b>Requisito:</b> Para practicantes con al menos 4 años de experiencia en el método Iyengar.</p>

            <p>💰 <b>Precio:</b></p>

            <ul class="lista_precios_seminario">
              <!-- <li>⚬ $225.000 (hasta el 20 de marzo precio promocional preventa)</li> -->
              <li>⚬ $258.750</li>
              <li>⚬ Pago en cuotas: 3 pagos de $86.250</li>
            </ul>
          </div>

          <p>⚠️ <b>Importante:</b></p>

          <p><b>Cierre de inscripción: 15 de abril</b> (sin excepciones).</p>
          <p>Todos los pagos deberán realizarse únicamente a través del portal web.</p>
          <p><b>Cupos limitados:</b> La vacante quedará reservada únicamente una vez realizado el <b>pago de la primera cuota o el pago total</b> de la inscripción.</p>
          <p>La <b>participación</b> en la convención está sujeta al <b>pago total</b> antes del <b>15 de abril</b>.</p>
          <p><b>No se realizarán cambios y/o devoluciones</b> una vez efectuado el pago, ya sea parcial o total.</p>

          <BaseLoader
            v-if="cargando"
          />
          <div v-else>
            <div class="seminario-confirmacion" v-if="mostrarOpcionesParticipacionSeminario">
              <h2>¿De qué forma deseas participar en la Convención 2025?</h2>
              <div class="contenedor_menu_almuerzo">
                <label for="forma_1">
                  <input type="radio" id="forma_1" value="Presencial" name="forma_1" v-model="formaSeminario" @change="elegirFormaSeminario(1)"/>
                  Presencial
                  <span class="checkmark2"></span>
                </label>
              </div>
              <div class="contenedor_menu_almuerzo  contenedor_menu_almuerzo_no_bottom">
                <label for="forma_2">
                  <input type="radio" id="forma_2" value="Online" name="forma_2" v-model="formaSeminario" @change="elegirFormaSeminario(2)"/>
                  Online
                  <span class="checkmark2"></span>
                </label>
              </div>
            </div>
            <div class="seminario-texto-box" :class="clase"  v-if="mostrarMensajeIncripcionSeminario">
              {{ this.texto }}
            </div>
            <div v-if="mostrarContenedorBotonesSeminario">
              <div class="seminario-price-box" v-if="mostrarBotonesSeminario">
                <h2>¿A qué programa deseas asistir?</h2>
                <p v-if="mostrarMensajeFaltaCuotaDos" class="text_cuota_2">{{ this.textoFaltaCuotaDos }}</p>
                    <p class="margen_nombre_seminario"><b>Programa General (2 días - Sábado y Domingo)</b></p>
                    <div class="contenedor_menu_almuerzo">
                      <label for="programa_1">
                        <input type="radio" id="programa_1" value="General-T" name="programa_1" v-model="nombreSeminario2" @change="elegircuota(37, 258750, 'General', 'Total', 1, 0)"/>
                        $258.750 (Pago Total)
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="programa_2">
                        <input type="radio" id="programa_2" value="General-1" name="programa_2" v-model="nombreSeminario2" @change="elegircuota(39, 86250, 'General', 'Cuota #1', 2, 1)"/>
                        $86.250 (Pago Cuota #1)
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <p class="margen_nombre_seminario"><b>Programa Completo (4 días)</b></p>
                    <div class="contenedor_menu_almuerzo">
                      <label for="programa_3">
                        <input type="radio" id="programa_3" value="Completo-T" name="programa_3" v-model="nombreSeminario2" @change="elegircuota(38, 517500, 'Completo', 'Total', 5, 0)"/>
                        $517.500 (Pago Total)
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo contenedor_menu_almuerzo_no_bottom">
                      <label for="programa_4">
                        <input type="radio" id="programa_4" value="Completo-1" name="programa_4" v-model="nombreSeminario2" @change="elegircuota(42, 172500, 'Completo', 'Cuota #1', 6, 1)"/>
                        $172.500 (Pago Cuota #1)
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div v-if="this.user.perfil_id == 1 || this.en_mentoria">
                      <p class="margen_nombre_seminario"><b>Programa Profesores (2 días - Jueves y Viernes)</b></p>
                      <div class="contenedor_menu_almuerzo">
                        <label for="programa_5">
                          <input type="radio" id="programa_5" value="Profesores-T" name="programa_5" v-model="nombreSeminario2" @change="elegircuota(51, 258750, 'Profesores', 'Total', 1, 0)"/>
                          $258.750 (Pago Total)
                          <span class="checkmark2"></span>
                        </label>
                      </div>
                      <div class="contenedor_menu_almuerzo">
                        <label for="programa_6">
                          <input type="radio" id="programa_6" value="Profesores-1" name="programa_6" v-model="nombreSeminario2" @change="elegircuota(48, 86250, 'Profesores', 'Cuota #1', 2, 1)"/>
                          $86.250 (Pago Cuota #1)
                          <span class="checkmark2"></span>
                        </label>
                      </div>
                    </div>
              </div>
            </div>
          </div>
          <div class="contenedor_general_merchandising" v-if="mostrarMerchandising">
            <p>Este año ofrecemos un <b>Kit de Props</b>, ideal para que no tengas que transportar tus propios elementos desde casa. La disponibilidad es limitada, por lo que puedes reservar tu kit ahora mismo y aprovechar el precio especial de <b>preventa con descuento</b>.</p>
            <div class="contenedor_general_item_merchandising">
              <h3>Kit</h3>
              <ul>
                <li>1 Bolso especialmente diseñado e ilustrado, con bolsillos y espacio para todos los elementos y tus apuntes de la convención</li>
                <li>2 Mantas 100% algodón de fabricación artesanal</li>
                <li>2 Bloques de madera</li>
                <li>2 Soportes de foam (modelo similares a los de RIMYI 2025)</li>
              </ul>
              <p><b>⚠️ Importante: </b>Los kits no pueden modificarse y se reservan en su totalidad. Cada participante que adquiera un kit deberá traer su propio <b>mat antideslizante, 2 cinturones</b> y cualquier otro elemento adicional que considere necesario para su práctica.</p>
              <div class="contenedor_bolso">
                <img src="@/assets/ladrillos.png" alt="Ladrillos">
                <img src="@/assets/manta.png" alt="Mantas">
                <img src="@/assets/foam.png" alt="Foam">
                <img src="@/assets/bolso_props.png" alt="Bolso con Props">
              </div>
              <p>Las imágenes son referenciales y no corresponden a los elementos originales. Serán actualizadas próximamente.</p>
              <p>Adquiérelo ahora a un precio promocional de <b>$222.000</b>.</p>
              <div class="contenedor_menu_almuerzo">
                <label for="kit_1">
                  <input type="radio" id="kit_1" value="no" name="kit_1" v-model="merchandising.kit.compra" @change="changeKit(1)" checked/>
                  No quiero Kit
                  <span class="checkmark2"></span>
                </label>
              </div>
              <div class="contenedor_menu_almuerzo">
                <label for="kit_2">
                  <input type="radio" id="kit_2" value="si" name="kit_2" v-model="merchandising.kit.compra" @change="changeKit(2)"/>
                  Quiero kit
                  <span class="checkmark2"></span>
                </label>
              </div>
              <p v-if="mostrarKitSeleccionado" class="merchandising_seleccionado">Kit seleccionado</p>
              <div class="form_section_merchandising" v-if="mostrarCantidadKit">
                <label for="cantidad_kit">Cantidad</label>
                <select id="cantidad_kit" class="minimal" v-model.number="merchandising.kit.cantidad">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
                <p v-if="mostrarTextoValidacionKit" class="form_mensaje_error_login mensaje_error_mechandising">{{ this.textoValidacionKit }}</p>
                <button @click="agregarKit(merchandising.kit.cantidad)" class="boton_opciones_merchandising">Agregar Kit</button>
              </div>
            </div>
            <div class="contenedor_general_item_merchandising">
              <h3>Remera</h3>
              <!-- <p>También puedes reservar tu remera (T-shirt) de la Convención: confeccionada en <b>100% algodón, de manga corta y cuello redondo</b>.</p> -->
              <p>¡También puedes reservar tu <b>remera (T-shirt)</b> de la convención a <b>precio de preventa!</b> Podrás retirarla al ingresar al evento.</p>
              <div class="contenedor_remeras">
                <img src="@/assets/remeras_finales.png" alt="Remeras">
              </div>
              <p>Las imágenes son referenciales y no corresponden al diseño final.</p>

              <p>Valor de la remera: <b>$21.000</b>.</p>
              <div class="contenedor_menu_almuerzo">
                <label for="remera_1">
                  <input type="radio" id="remera_1" value="no" name="remera_1" v-model="merchandising.remera.compra" @change="changeRemera(1)" checked/>
                  No quiero Remera
                  <span class="checkmark2"></span>
                </label>
              </div>
              <div class="contenedor_menu_almuerzo">
                <label for="remera_2">
                  <input type="radio" id="remera_2" value="si" name="remera_2" v-model="merchandising.remera.compra" @change="changeRemera(2)"/>
                  Quiero Remera
                  <span class="checkmark2"></span>
                </label>
              </div>
              <p v-if="mostrarRemeraSeleccionada" class="merchandising_seleccionado">Remera seleccionada</p>
              <div v-if="mostrarRemera">
                <div v-if="mostrarRemeraSelect">
                  <!-- <div class="contenedor_talles_remeras">
                     <img src="@/assets/talles_remeras.jpeg" alt="Talles Remeras">
                     <p>Talle S: A = 48 cm | B = 70 cm</p>
                     <p>Talle M: A = 54 cm | B = 73 cm</p>
                     <p>Talle L: A = 57.5 cm | B = 76 cm</p>
                     <p>Talle XL: A = 61 cm | B = 81.5 cm</p>
                  </div> -->
                  <!-- <div class="form_section_merchandising">
                    <label for="talle">Talle</label>
                    <select id="talle" class="minimal" v-model.number="producto.talle_id" @change="talleNombre">
                      <option value="1" data-talle="XS">XS</option>
                      <option value="2" data-talle="S">S</option>
                      <option value="3" data-talle="M">M</option>
                      <option value="4" data-talle="L">L</option>
                      <option value="5" data-talle="XL">XL</option>
                    </select>
                  </div>
                  <div class="form_section_merchandising">
                    <label for="color">Color</label>
                    <select id="color" class="minimal" v-model.number="producto.color_id" @change="colorNombre">
                      <option value="1" data-color="Off White">Off White</option>
                      <option value="2" data-color="Naranja">Naranja</option>
                    </select>
                  </div> -->
                  <div class="form_section_merchandising">
                    <label for="cantidad_remera">Cantidad</label>
                    <select id="cantidad_remera" class="minimal" v-model.number="merchandising.remera.cantidad">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                  <p v-if="mostrarTextoValidacionRemera" class="form_mensaje_error_login mensaje_error_mechandising">{{ this.textoValidacionRemera }}</p>
                  <button @click="agregarRemera(merchandising.remera.cantidad)" class="boton_opciones_merchandising">Agregar Remera</button>
                </div>
                <!-- <button v-else @click="activarSelectRemera" class="boton_otra_remera">Agregar Otra Remera</button> -->
              </div>
            </div>
            <div class="contenedor_general_item_merchandising">
              <div class="contenedor_general_merchandising">
                <h3>Almuerzo</h3>
                <p>Este año nos encargamos de tu almuerzo. Podrás elegir entre <b>dos opciones de menú</b> para la comida del mediodía, y tu elección estará lista al finalizar la práctica de la mañana.</p>

                <p>El servicio de catering es <b>vegetariano, saludable y nutritivo</b>, pensado especialmente para los días de práctica. Todos los menús incluyen una bebida a elección: agua mineral o jugo natural.</p>

                <p>El salón contará con <b>dispensadores de agua fría y caliente</b>.</p>

                <p><b>⚠️ Importante: Sólo podrás comprar almuerzos anticipadamente</b> con tu inscripción a la convención. <b>No estará disponible la venta durante el evento.</b></p>

                <p>Valor por menú: <b>$15.000</b>.</p>
                <div class="contenedor_menu_almuerzo">
                  <label for="almuerzo_1">
                    <input type="radio" id="almuerzo_1" value="no" name="almuerzo_1" v-model="merchandising.almuerzo.compra" @change="changeAlmuerzo(1)" checked/>
                    No quiero Almuerzo
                    <span class="checkmark2"></span>
                  </label>
                </div>
                <div class="contenedor_menu_almuerzo">
                  <label for="almuerzo_2">
                    <input type="radio" id="almuerzo_2" value="si" name="almuerzo_2" v-model="merchandising.almuerzo.compra" @change="changeAlmuerzo(2)"/>
                    Quiero Almuerzo
                    <span class="checkmark2"></span>
                  </label>
                </div>
              </div>
              <div v-if="mostrarAlmuerzo">
                <div>
                  <p class="almuerzo_dia">Jueves 1 de Mayo</p>
                  <div class="contenedor_general_menu">
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_1_1">
                        <input type="radio" id="menu_1_1" value="1" v-model="merchandising.almuerzo.dias.dia_1.almuerzo_id" @change="pickAlmuerzo(1, 'Menú 1')"/>
                        <span>Menú 1: </span>Base de queso Tybo, acompañada de tomates seleccionados y lechuga fresca de huerta. Ensalada de quinoa con calabaza, castañas de cajú, almendras tostadas y garbanzos spicy.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_1_2">
                        <input type="radio" id="menu_1_2" value="2" v-model="merchandising.almuerzo.dias.dia_1.almuerzo_id" @change="pickAlmuerzo(1, 'Menú 2')"/>
                        <span>Menú 2: </span>Base de hummus casero, acompañada de vegetales grillados sobre un colchón de hojas verdes. Ensalada de quinoa con calabaza, castañas de cajú, almendras tostadas y garbanzos spicy.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="no_menu_1">
                        <input type="radio" id="no_menu_1" value="no_menu" v-model="merchandising.almuerzo.dias.dia_1.almuerzo_id" @change="pickAlmuerzo(1, 'no_menu')" checked/>
                        No deseo almuerzo este día
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="almuerzo_dia">Viernes 2 de Mayo</p>
                  <div class="contenedor_general_menu">
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_2_1">
                        <input type="radio" id="menu_2_1" value="3" v-model="merchandising.almuerzo.dias.dia_2.almuerzo_id" @change="pickAlmuerzo(2, 'Menú 1')"/>
                        <span>Menú 1: </span>Lasagna de finísimas capas de masa casera rellenas de ricota, espinacas salteadas y nuez moscada, cubierta con salsa blanca.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_2_2">
                        <input type="radio" id="menu_2_2" value="4" v-model="merchandising.almuerzo.dias.dia_2.almuerzo_id" @change="pickAlmuerzo(2, 'Menú 2')"/>
                        <span>Menú 2: </span> Base de arroz basmati con tofu, tomates cherry, rúcula y semillas de sésamo integral.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="no_menu_2">
                        <input type="radio" id="no_menu_2" value="no_menu" v-model="merchandising.almuerzo.dias.dia_2.almuerzo_id" @change="pickAlmuerzo(2, 'no_menu')" checked/>No deseo almuerzo este día
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="almuerzo_dia">Sábado 3 de Mayo</p>
                  <div class="contenedor_general_menu">
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_3_1">
                        <input type="radio" id="menu_3_1" value="5" v-model="merchandising.almuerzo.dias.dia_3.almuerzo_id" @change="pickAlmuerzo(3, 'Menú 1')"/>
                        <span>Menú 1: </span>Ensalada de curry de garbanzos, arroz basmati, aceite de oliva y toque de comino.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_3_2">
                        <input type="radio" id="menu_3_2" value="6" v-model="merchandising.almuerzo.dias.dia_3.almuerzo_id" @change="pickAlmuerzo(3, 'Menú 2')"/>
                        <span>Menú 2: </span>Bowls de proteínas con quinoa, morrón, rabanitos, cilantro fresco y lima.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="no_menu_3">
                        <input type="radio" id="no_menu_3" value="no_menu" v-model="merchandising.almuerzo.dias.dia_3.almuerzo_id" @change="pickAlmuerzo(3, 'no_menu')" checked/>No deseo almuerzo este día
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <p class="almuerzo_dia">Domingo 4 de Mayo</p>
                  <div class="contenedor_general_menu">
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_4_1">
                        <input type="radio" id="menu_4_1" value="7" name="menu_1" v-model="merchandising.almuerzo.dias.dia_4.almuerzo_id" @change="pickAlmuerzo(4, 'Menú 1')"/>
                        <span>Menú 1: </span>Tacos de porotos cocidos, arroz doble, morrón asado, queso blanco, zanahoria glaseada, berenjenas asadas y zapallitos.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="menu_4_2">
                        <input type="radio" id="menu_4_2" value="8" name="menu_2" v-model="merchandising.almuerzo.dias.dia_4.almuerzo_id" @change="pickAlmuerzo(4, 'Menú 2')"/>
                        <span>Menú 2: </span>Hamburguesas veggie de lentejas cocidas, ajo, pimiento rojo, cebolla, zanahoria, comino y cúrcuma.
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                    <div class="contenedor_menu_almuerzo">
                      <label for="no_menu_4">
                        <input type="radio" id="no_menu_4" value="no_menu" name="no_menu" v-model="merchandising.almuerzo.dias.dia_4.almuerzo_id" @change="pickAlmuerzo(4, 'no_menu')" checked/>No deseo almuerzo este día
                        <span class="checkmark2"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="contenedor_resumen_compra_seminario" v-if="mostrarResumenDeCompra">
            <h2>Resumen de compra</h2>
            <div class="contenedor-item-resumen-compra">
              <h3>Programa {{nombreSeminario}} ({{formaSeminario}})</h3>
              <p class="reumen-compra-sudtotal">$ {{ dotsCadaTres(parseFloat(montoSeminario).toFixed(2).replace(/\.00$/, '')) }} (Pago {{cuotaNombre}})</p>
            </div>
            <div class="contenedor-item-resumen-compra" v-if="mostrarResumenDeCompraKit">
              <h3 v-if="this.merchandising.kit.cantidad < 2">Kit</h3>
              <h3 v-else>Kits</h3>
              <div class="contenedor_confirmacion_kit">
                <p>Cantidad: {{ merchandising.kit.cantidad}}</p>
                <button @click="borrarKits" class="icon icon-trash-o borrar_merchandising"></button>
              </div>
              <p class="reumen-compra-sudtotal">$ {{ dotsCadaTres(parseFloat(this.merchandising.kit.precio_total).toFixed(2).replace(/\.00$/, '')) }}</p>
            </div>
            <div class="contenedor-item-resumen-compra" v-if="mostrarResumenDeCompraRemera">
              <h3 v-if="this.merchandising.remera.cantidad < 2">Remera</h3>
              <h3 v-else>Remeras</h3>
              <div class="contenedor_confirmacion_kit">
                <p>Cantidad: {{ merchandising.remera.cantidad}}</p>
                <button @click="borrarRemeras" class="icon icon-trash-o borrar_merchandising"></button>
              </div>
              <!-- <table class="tabla_remeras_confirmacion">
                <tr>
                  <th>Talle</th>
                  <th>Color</th>
                  <th>Cantidad</th>
                  <th></th>
                </tr>
                <tr
                  v-for="prod in merchandising.remera.productos"
                  :key="prod.talle_id"
                >
                  <td>{{ prod.talle }}</td>
                  <td>{{ prod.color }}</td>
                  <td>{{ prod.cantidad }}</td>
                  <td class="contenedor-boton-borrar"><button @click="borrarRemeras" class="icon icon-trash-o borrar_merchandising"></button></td>
                </tr>
              </table> -->
              <p class="reumen-compra-sudtotal">$ {{ dotsCadaTres(parseFloat(this.merchandising.remera.precio_total).toFixed(2).replace(/\.00$/, '')) }}</p>
            </div>
            <div class="contenedor-item-resumen-compra" v-if="mostrarResumenDeCompraAlmuerzo">
              <h3>Almuerzo</h3>
              <div
                v-for="almuerzo in merchandising.almuerzo.dias"
                :key="almuerzo.dia_id"
              >
                <div v-if="almuerzo.almuerzo_id !== 'no_menu'">
                  <h4>{{ almuerzo.dia_nombre }}</h4>
                  <div class="contenedor_info_almuerzo_dia">
                    <span>{{ almuerzo.almuerzo_nombre }}</span>
                    <button @click="borrarMenu(almuerzo.dia_id)" class="icon icon-trash-o borrar_merchandising"></button>
                  </div>
                </div>
              </div>
              <p class="reumen-compra-sudtotal">$ {{ dotsCadaTres(parseFloat(this.merchandising.almuerzo.precio_total).toFixed(2).replace(/\.00$/, '')) }}</p>
            </div>
            <p class="contenedor-item-resumen-compra reumen-compra-sudtotal">Total a Pagar: $ {{ dotsCadaTres(parseFloat(this.montoSeminarioTotal).toFixed(2).replace(/\.00$/, '')) }}</p>
            <p v-if="mostrar" class="warning_no_profe">Por favor, completa el DNI y el domicilio en tu perfil para poder realizar tu pago</p>
            <button class="boton_pagar_seminario" @click="pagarSaminario()">Pagar</button>
          </div>
          <p>Consultas a <a href="mailto:seminarios@asociacioniyengar.com.ar" target="_blank"><b>seminarios@asociacioniyengar.com.ar</b></a></p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import pagosService from '../services/pagos'
import asociadoService from '../services/asociado'
import mentorService from '../services/mentor'
import BaseLoader from '../components/BaseLoader.vue'
const mercadopago = require('mercadopago')
export default {
  name: 'Convencion2025Socio',
  components: {
    BaseLoader
  },
  data: function () {
    return {
      en_mentoria: false,
      servicio_id: 20,
      forma_de_seminario_id: null,
      cuota_id: null,
      montoSeminario: null,
      montoSeminarioTotal: null,
      nombreSeminario: null,
      nombreSeminario2: null,
      formaSeminario: null,
      cuotaNombre: null,
      pagoTotal: null,
      modo_de_pago_id: null,
      descripcioSemonarioElegido: null,
      textoMercadoPago: null,
      merchandising: {
        remera: {
          compra: 'no',
          // precio_individual: 2,
          precio_individual: 21000,
          precio_total: null,
          producto_id: 11,
          cantidad: null
        },
        kit: {
          compra: 'no',
          producto_id: 12,
          cantidad: null,
          // precio_individual: 3,
          precio_individual: 222000,
          precio_total: null
        },
        almuerzo: {
          compra: 'no',
          // precio_individual: 1,
          precio_individual: 15000,
          precio_total: null,
          cantidad_dias: null,
          dias: {
            dia_1: {
              dia_id: 1,
              dia_nombre: 'Jueves 1 de Mayo',
              almuerzo_id: 'no_menu',
              almuerzo_nombre: 'no_menu'
            },
            dia_2: {
              dia_id: 2,
              dia_nombre: 'Viernes 2 de Mayo',
              almuerzo_id: 'no_menu',
              almuerzo_nombre: 'no_menu'
            },
            dia_3: {
              dia_id: 3,
              dia_nombre: 'Sábado 3 de Mayo',
              almuerzo_id: 'no_menu',
              almuerzo_nombre: 'no_menu'
            },
            dia_4: {
              dia_id: 4,
              dia_nombre: 'Domingo 4 de Mayo',
              almuerzo_id: 'no_menu',
              almuerzo_nombre: 'no_menu'
            }
          }
        },
        precio_total_merchandising: null
      },
      producto: {
        producto_id: 11, // OJO QUE ESTO CAMBIA
        cantidad: null,
        talle_id: null,
        talle: null,
        color_id: null,
        color: null
      },
      form: false,
      cargando: false,
      texto: null,
      clase: null,
      textoValidacionRemera: null,
      textoValidacionKit: null,
      textoFaltaCuotaDos: 'ffffff',
      mostrarResumenDeCompra: false,
      mostrarMensajeFormulario: false,
      mostrarMensajeIncripcionSeminario: false,
      mostrarContenedorBotonesSeminario: false,
      mostrarBotonesSeminario: false,
      mostrarMensajeFaltaCuotaDos: false,
      mostrarCantidadKit: false,
      mostrarRemera: false,
      mostrarAlmuerzo: false,
      mostrarRemeraSeleccionada: false,
      mostrarResumenDeCompraRemera: false,
      mostrarResumenDeCompraKit: false,
      mostrarResumenDeCompraAlmuerzo: false,
      mostrarKitSeleccionado: false,
      mostrarTextoValidacionKit: false,
      mostrarRemeraSelect: true,
      mostrarTextoValidacionRemera: false,
      mostrarMerchandising: false,
      mostrarOpcionesParticipacionSeminario: false,
      mostrar: false,
      socio: [],
      linkMercadoPago: {
        link: null
      },
      user: {
        tipo_id: JSON.parse(localStorage.getItem('userData')).tipo_id,
        perfil_id: JSON.parse(localStorage.getItem('userData')).perfil_id,
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id,
        nombre: JSON.parse(localStorage.getItem('userData')).nombre,
        apellido: JSON.parse(localStorage.getItem('userData')).apellido,
        dni: JSON.parse(localStorage.getItem('userData')).dni,
        direccion_1: JSON.parse(localStorage.getItem('userData')).direccion_1,
        provincia_id_1: JSON.parse(localStorage.getItem('userData')).provincia_id_1
      }
    }
  },
  methods: {
    sumaTotalSeminario () {
      this.montoSeminarioTotal = this.montoSeminario + this.merchandising.remera.precio_total + this.merchandising.kit.precio_total + this.merchandising.almuerzo.precio_total
      this.merchandising.precio_total_merchandising = this.merchandising.remera.precio_total + this.merchandising.kit.precio_total + this.merchandising.almuerzo.precio_total
      if (this.merchandising.precio_total_merchandising === 0) {
        this.merchandising.precio_total_merchandising = null
      }
    },
    activarSelectRemera () {
      this.mostrarRemeraSelect = true
      this.mostrarRemeraSeleccionada = false
    },
    colorNombre () {
      console.log(this.producto.color_id)
      const nodeMap = document.getElementById('color').childNodes
      console.log(nodeMap.length)
      for (let i = 0; i < nodeMap.length; i++) {
        if (nodeMap[i].value === this.producto.color_id.toString()) {
          console.log(nodeMap[i].getAttribute('data-color'))
          this.producto.color = nodeMap[i].getAttribute('data-color').toString()
        }
      }
    },
    talleNombre () {
      console.log(this.producto.talle_id)
      const nodeMap = document.getElementById('talle').childNodes
      console.log(nodeMap.length)
      for (let i = 0; i < nodeMap.length; i++) {
        if (nodeMap[i].value === this.producto.talle_id.toString()) {
          console.log(nodeMap[i].getAttribute('data-talle'))
          this.producto.talle = nodeMap[i].getAttribute('data-talle').toString()
        }
      }
    },
    borrarAlmuerzosTodos () {
      const almuerzoDias = this.merchandising.almuerzo.dias
      console.log(Object.keys(this.merchandising.almuerzo.dias).length)
      for (const dia in almuerzoDias) {
        const diaObj = almuerzoDias[dia]
        diaObj.almuerzo_id = 'no_menu'
      }
      this.cantidadAlmuerzo()
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
      this.merchandising.almuerzo.compra = 'no'
      if (this.merchandising.almuerzo.precio_total === null) {
        this.mostrarResumenDeCompraAlmuerzo = false
        this.mostrarAlmuerzo = false
      }
    },
    borrarMenu (id) {
      console.log(id)
      const almuerzoDias = this.merchandising.almuerzo.dias
      console.log(Object.keys(this.merchandising.almuerzo.dias).length)
      for (const dia in almuerzoDias) {
        const diaObj = almuerzoDias[dia]
        console.log(diaObj.almuerzo_id)
        console.log(diaObj)
        if (diaObj.dia_id === id) {
          diaObj.almuerzo_id = 'no_menu'
        }
      }
      this.cantidadAlmuerzo()
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
      this.merchandising.almuerzo.compra = 'no'
      if (this.merchandising.almuerzo.precio_total === null) {
        this.mostrarResumenDeCompraAlmuerzo = false
        this.mostrarAlmuerzo = false
      }
    },
    borrarKits () {
      this.merchandising.kit.compra = 'no'
      this.merchandising.kit.cantidad = null
      this.merchandising.kit.precio_total = null
      this.mostrarCantidadKit = false
      this.mostrarKitSeleccionado = false
      this.mostrarResumenDeCompraKit = false
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    borrarRemeras () {
      // const remeras = this.merchandising.remera.productos
      // for (let i = 0; i < remeras.length; i++) {
      //   if (remeras[i].talle_id === talleId) {
      //     if (remeras[i].color_id === colorId) {
      //       if (remeras[i].cantidad === cantidad) {
      //         console.log('uuuuuuuu')
      //         remeras.splice(i, 1)
      //       }
      //     }
      //   }
      // }
      // if (remeras.length === 0) {
      //   this.mostrarResumenDeCompraRemera = false
      // }
      this.mostrarRemeraSeleccionada = false
      this.merchandising.remera.compra = 'no'
      this.merchandising.remera.cantidad = null
      this.merchandising.remera.precio_total = null
      this.mostrarRemera = false
      this.mostrarRemeraSelect = true
      this.mostrarResumenDeCompraRemera = false
      this.calcularCantidadRemeras()
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    borrarRemerasTodas () {
      // this.merchandising.remera.productos = []
      this.mostrarResumenDeCompraRemera = false
      this.mostrarRemeraSelect = true
      this.calcularCantidadRemeras()
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    dotsCadaTres (dni) {
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    elegircuota (id, valor, nombreSeminario, cuotaNombre, numeroBoton, cuotaNumero) {
      // const botones = document.querySelectorAll('.boton_pago_seminario')
      // for (let i = 0; i < botones.length; i++) {
      //   botones[i].classList.remove('boton_pago_seminario_selected')
      // }
      this.cuota_id = id
      this.montoSeminario = valor
      // this.montoSeminario = 3 // CAMBIAR ------------------------
      this.nombreSeminario = nombreSeminario
      this.cuotaNombre = cuotaNombre
      if (cuotaNombre === 'Total') {
        this.pagoTotal = 'si'
        this.descripcioSemonarioElegido = 'Pago Total'
        this.modo_de_pago_id = 1
      } else {
        this.pagoTotal = 'no'
        this.descripcioSemonarioElegido = 'Pago Cuota # ' + cuotaNumero
        this.modo_de_pago_id = 2
      }
      // document.querySelector('.boton_pago_seminario_' + numeroBoton).classList.add('boton_pago_seminario_selected')
      this.mostrarResumenDeCompra = true
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    // este es para la validacion - para como mostrar despues que pago una cuota
    elegircuota2 (id, valor, nombreSeminario, cuotaNombre, numeroBoton, cuotaNumero) {
      this.cuota_id = id
      this.montoSeminario = valor
      // this.montoSeminario = 3 // CAMBIAR ------------------------
      this.nombreSeminario = nombreSeminario
      this.cuotaNombre = cuotaNombre
      if (cuotaNombre === 'Total') {
        this.pagoTotal = 'si'
        this.descripcioSemonarioElegido = 'Pago Total'
        this.modo_de_pago_id = 1
      } else {
        this.pagoTotal = 'no'
        this.descripcioSemonarioElegido = 'Pago Cuota # ' + cuotaNumero
        this.modo_de_pago_id = 2
      }
      this.mostrarResumenDeCompra = true
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    agregarKit (cantidad) {
      console.log(cantidad)
      if (cantidad == null) {
        this.textoValidacionKit = 'Seleccionar Cantidad'
        this.mostrarTextoValidacionKit = true
        return
      }
      this.merchandising.kit.cantidad = cantidad
      this.mostrarCantidadKit = false
      this.mostrarKitSeleccionado = true
      this.mostrarResumenDeCompraKit = true
      this.kitPrecioTotal()
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    kitPrecioTotal () {
      this.merchandising.kit.precio_total = this.merchandising.kit.cantidad * this.merchandising.kit.precio_individual
    },
    calcularCantidadRemeras () {
      // const botones = this.merchandising.remera.productos
      // let cantidad = 0
      // for (let i = 0; i < botones.length; i++) {
      //   cantidad += botones[i].cantidad
      // }
      this.merchandising.remera.precio_total = this.merchandising.remera.precio_individual * this.merchandising.remera.cantidad
    },
    agregarRemera (cantidad) {
      // if (this.producto.talle_id == null) {
      //   this.textoValidacionRemera = 'Seleccionar Talle'
      //   this.mostrarTextoValidacionRemera = true
      //   console.log('pone la cantidad boludo')
      //   return
      // }
      // if (this.producto.color_id == null) {
      //   this.textoValidacionRemera = 'Seleccionar Color'
      //   this.mostrarTextoValidacionRemera = true
      //   console.log('pone la cantidad boludo')
      //   return
      // }
      if (this.merchandising.remera.cantidad == null) {
        this.textoValidacionRemera = 'Seleccionar Cantidad'
        this.mostrarTextoValidacionRemera = true
        console.log('pone la cantidad boludo')
        return
      }
      this.mostrarTextoValidacionRemera = false
      this.textoValidacionRemera = null
      this.merchandising.remera.cantidad = cantidad
      this.calcularCantidadRemeras()
      this.producto = {
        producto_id: 11,
        cantidad: null,
        talle_id: null,
        talle: null,
        color_id: null,
        color: null
      }
      this.mostrarRemeraSelect = false
      this.mostrarRemeraSeleccionada = true
      this.mostrarResumenDeCompraRemera = true
      this.sumaTotalSeminario()
      this.textoVisibleMercadoPago()
    },
    elegirFormaSeminario (id) {
      this.mostrarContenedorBotonesSeminario = true
      if (id === 1) {
        this.mostrarMerchandising = true
        this.forma_de_seminario_id = 1
      } else {
        this.mostrarMerchandising = false
        this.forma_de_seminario_id = 2
        this.borrarRemeras()
        this.borrarKits()
        this.borrarAlmuerzosTodos()
      }
    },
    changeRemera (id) {
      console.log(id)
      if (id === 2) {
        this.mostrarRemera = true
      } else {
        this.mostrarRemera = false
        this.mostrarRemeraSeleccionada = false
        this.borrarRemeras()
      }
    },
    changeAlmuerzo (id) {
      console.log(id)
      if (id === 2) {
        this.mostrarAlmuerzo = true
      } else {
        this.mostrarAlmuerzo = false
        this.borrarAlmuerzosTodos()
      }
    },
    changeKit (id) {
      console.log(id)
      if (id === 2) {
        this.mostrarCantidadKit = true
        this.mostrarTextoValidacionKit = false
      } else {
        this.mostrarCantidadKit = false
        this.mostrarKitSeleccionado = false
        this.borrarKits()
      }
      this.textoVisibleMercadoPago()
    },
    cantidadAlmuerzo () {
      const almuerzoDias = this.merchandising.almuerzo.dias
      let cantidad = 0
      for (const dia in almuerzoDias) {
        const diaObj = almuerzoDias[dia]
        if (diaObj.almuerzo_id !== 'no_menu') {
          cantidad += 1
        }
      }
      if (cantidad !== 0) {
        this.merchandising.almuerzo.precio_total = cantidad * this.merchandising.almuerzo.precio_individual
        this.merchandising.almuerzo.cantidad_dias = cantidad
      } else {
        this.merchandising.almuerzo.precio_total = null
        this.merchandising.almuerzo.cantidad_dias = null
      }
    },
    pickAlmuerzo (diaSeleccionado, nombre) {
      const almuerzoDias = this.merchandising.almuerzo.dias
      for (const dia in almuerzoDias) {
        const diaObj = almuerzoDias[dia]
        if (diaObj.dia_id === diaSeleccionado) {
          console.log('adentro')
          diaObj.almuerzo_nombre = nombre
        }
      }
      this.cantidadAlmuerzo()
      this.sumaTotalSeminario()
      this.mostrarResumenDeCompraAlmuerzo = true
      this.textoVisibleMercadoPago()
    },
    textoVisibleMercadoPago () {
      console.log('textoVisibleMercadoPago')
      let text = 'Convención Anual 2025 - Programa ' + this.nombreSeminario
      if (this.merchandising.kit.cantidad !== null) {
        if (this.merchandising.kit.cantidad === 1) {
          text += ' + Kit'
        } else {
          text += ' + Kits'
        }
      }
      if (this.merchandising.remera.cantidad !== null) {
        if (this.merchandising.remera.cantidad === 1) {
          text += ' + Remera'
        } else {
          text += ' + Remeras'
        }
      }
      if (this.merchandising.almuerzo.precio_total !== null) {
        if (this.merchandising.almuerzo.precio_total !== 0) {
          text += ' + Almuerzo'
        }
      }
      this.textoMercadoPago = text
    },
    ckeckFormulario () {
      if (this.form === false) {
        this.mostrarMensajeFormulario = true
      } else {
        this.mostrarMensajeFormulario = false
      }
    },
    pagarSaminario () {
      if (this.socio.dni == null || this.socio.direccion_1 == null) {
        this.mostrar = true
      } else {
        const preference = {
          // trackingId: 77,
          back_urls: {
            failure: 'https://portal.asociacioniyengar.com.ar/#/pago-rechazado',
            success: 'https://portal.asociacioniyengar.com.ar/#/pago-exitoso'
          },
          items: [
            {
              title: this.textoMercadoPago,
              unit_price: this.montoSeminarioTotal,
              quantity: 1
            }
          ],
          metadata: {
            persona_id: this.socio.persona_id,
            nombre: this.socio.nombre,
            apellido: this.socio.apellido,
            email: this.socio.email,
            direccion: this.socio.direccion_1,
            provincia: this.socio.provincia_id_1,
            dni: this.socio.dni,
            concepto_id: 5,
            forma_de_pago_id: 3,
            modo_de_pago_id: this.modo_de_pago_id,
            forma_de_seminario_id: this.forma_de_seminario_id,
            moneda_id: 1,
            servicio_id: this.servicio_id,
            cuota_id: this.cuota_id,
            precio_seminario: this.montoSeminario,
            pago_concepto: 'Seminario 2025',
            descripcion: 'Convención Anual 2025 ' + '(Programa ' + this.nombreSeminario + ' - ' + this.formaSeminario + ' - ' + this.descripcioSemonarioElegido + ')',
            // fecha_inicio: String(respuesta[0].fecha_inicio),
            // fecha_finalizacion: String(respuesta[0].fecha_finalizacion),
            precio: this.montoSeminarioTotal,
            merchandising: Array(this.merchandising)
            // precio_total: Number(respuesta[respuesta.length - 1].precio_total),
            // descripcion: 'Pago Cuota Social ' + new Date().getFullYear(),
            // year: new Date().getFullYear()
          },
          payer: {
            name: this.socio.nombre,
            surname: this.socio.apellido
          },
          notification_url: 'https://api.asociacioniyengar.com.ar/api/webhooks-seminario',
          // notification_url: 'https://a7a3-2800-2206-6040-25e5-3dae-5537-8452-7ea4.ngrok-free.app/AAYI_API/api/public/api/webhooks-seminario',
          binary_mode: true,
          auto_return: 'approved',
          external_reference: this.socio.nombre + ' ' + this.socio.apellido + ' - ' + new Date().getTime().toString(),
          statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
        }
        mercadopago.configure({
          access_token: 'APP_USR-6447832264591121-032711-ad6690b0c38d8e10440c46daab8b7204-1323684790'
          // Credenciales de Prueba
          // access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
        })
        mercadopago.preferences
          .create(preference)
          .then(response => {
            console.log('mercadopago: ', response.body)
            console.log(new Date().getTime())
            // console.log(this.linkMercadoPago.link)
            this.linkMercadoPago.link = response.body.init_point
            // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
            window.location.href = this.linkMercadoPago.link
          })
          .catch(function (error) {
            console.log('MP ERROR!!!!!')
            console.log(error)
          })
      }
    }
  },
  mounted: function () {
    this.cargando = true
    asociadoService
      .traerSocioPorId(this.user.persona_id)
      .then(respuesta => {
        this.socio = respuesta
        console.log('Socio:', respuesta)
      })
    mentorService
      .checkMentorPorId(this.user.persona_id)
      .then(respuesta => {
        console.log('Mentor: ' + respuesta.success)
        if (respuesta.success) {
          this.en_mentoria = true
        }
      })
    pagosService
      .validarSocioAlDia(this.user.persona_id)
      .then(respuesta => {
        this.cargando = false
        console.log('Validar Socio:', respuesta)
        if (respuesta.success) {
          this.mostrarBotonesSeminario = true
          pagosService
            .checkPagoSeminario(this.user.persona_id, this.servicio_id)
            .then(respuesta => {
              console.log('Pago:', respuesta.data)
              if (respuesta.data.info === 'no_pago') {
                this.mostrarMensajeIncripcionSeminario = false
                this.mostrarOpcionesParticipacionSeminario = true
              } else {
                this.mostrarBotonesSeminario = false
                if (respuesta.data.forma_de_seminario_id === 1) {
                  this.forma_de_seminario_id = 1
                } else {
                  this.forma_de_seminario_id = 2
                }
              }
              if (respuesta.data.cantidad_cuotas_pagadas === 1) {
                console.log('cuotas pagas: ' + 1)
                this.formaSeminario = respuesta.data.forma_seminario
                this.mostrarResumenDeCompra = true
                if (respuesta.data.seminario === 'general') {
                  this.elegircuota2(40, 86250, 'General', 'Cuota #2', 3, 2)
                }
                if (respuesta.data.seminario === 'completo') {
                  this.elegircuota2(43, 172500, 'Completo', 'Cuota #2', 7, 2)
                }
                if (respuesta.data.seminario === 'profesores') {
                  this.elegircuota2(49, 86250, 'Profesores', 'Cuota #2', 7, 2)
                }
                if (respuesta.data.forma_de_seminario_id === 1) {
                  this.mostrarMerchandising = true
                }
                this.mostrarMensajeIncripcionSeminario = true
                this.mostrarMerchandising = true
              }
              if (respuesta.data.cantidad_cuotas_pagadas === 2) {
                console.log('cuotas pagas: ' + 2)
                this.formaSeminario = respuesta.data.forma_seminario
                this.mostrarResumenDeCompra = true
                if (respuesta.data.seminario === 'general') {
                  this.elegircuota2(41, 86250, 'General', 'Cuota #3', 4, 3)
                }
                if (respuesta.data.seminario === 'completo') {
                  this.elegircuota2(44, 172500, 'Completo', 'Cuota #3', 8, 3)
                }
                if (respuesta.data.seminario === 'profesores') {
                  this.elegircuota2(50, 86250, 'Profesores', 'Cuota #3', 4, 3)
                }
                if (respuesta.data.forma_de_seminario_id === 1) {
                  this.mostrarMerchandising = true
                }
                this.mostrarMensajeIncripcionSeminario = true
                this.mostrarMerchandising = true
              }
              if (respuesta.data.pago === 'total') {
                this.mostrarMensajeIncripcionSeminario = true
              }
              this.texto = respuesta.data.mensaje
            })
        } else {
          this.mostrarBotonesSeminario = false
          this.texto = respuesta.mensaje
          this.clase = 'fondo-rojo'
          this.mostrarMensajeIncripcionSeminario = true
        }
      })
  }
}
</script>
