import { API } from './../constants'
import authService from './auth.js'

const mentorService = {
  async checkMentorPorId (id) {
    const fetchResponse = await fetch(`${API}/mentor/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        ...authService.tokenHeader()
      }
    })
    const respuesta = await fetchResponse.json()
    return { ...respuesta }
  }
}

export default mentorService
