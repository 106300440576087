<template>
  <tr>
    <td>{{ fecha(venta.fecha_de_venta) }}</td>
    <td>{{ venta.persona.nombre }}</td>
    <td>{{ venta.persona.apellido }}</td>
    <td>{{ venta.persona.email }}</td>
    <td>{{ venta.persona.celular }}</td>
    <td>{{ venta.cantidad }}</td>
  </tr>
</template>

<script>
// import comerciosService from '../services/comercios'
// import store from './../store'
// import { PATH_IMG } from '../constants'
export default {
  name: 'KitsListaItem',
  props: ['venta'],
  data: function () {
    return {
      // store
    }
  },
  methods: {
    fecha (fecha) {
      const year = fecha.slice(0, 4)
      const month = fecha.slice(5, 7)
      const day = fecha.slice(8, 10)
      return day + '/' + month + '/' + year
    },
    dotsCadaTres (dni) {
      if (dni == null) {
        return null
      }
      const number = dni.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
      return number
    },
    abrirModal (id) {
      console.log(id)
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'flex'
    },
    cerrarModal (id) {
      const confirmation = document.querySelector('.confirmation-' + id)
      confirmation.style.display = 'none'
    }
    // tipo (tipo) {
    //   switch (tipo) {
    //     case 1:
    //       return 'comercio_tipo_restaurant'
    //     case 2:
    //       return 'comercio_tipo_hotel'
    //     case 3:
    //       return 'comercio_tipo_bar'
    //   }
    //   return 'comercio_tipo_restaurant'
    // }
  },
  computed: {
    // imagenRuta: function () {
    //   return `${PATH_IMG}/${this.comercio.imagen}`
    // }
  }
}
</script>

<style>

</style>
